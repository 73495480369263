body,
html,
#root {
  height: 100%;
}

html {
  font-size: 18px;
}

h1 {
  padding: .77rem 0;
  background-color: #010c3f;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 1.88rem;
  color: #fff;
  width: 100%;
}

h1 .highlight {
  color: #6c41ec;
}

h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.55rem;
}

.App {
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.AppContainer {
  display: flex;
  background-image: url('./background.jpg'); /* Correct syntax for background image */
  background-size: cover; /* Ensure the image covers the entire background */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  width: 100%;

}

.AppPlaylistContainer {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.SearchResults, .Playlist {
  flex: 1;
  margin: 0 10px;
}

@media only screen and (max-width: 1020px) {
  .App-playlist {
    align-items: center;
    flex-direction: column;
  }
}