.SearchResults {
    width: 50%;
    height: 950px;
    overflow-y: scroll;
    background-color: black;
    max-height: 950px;
    padding: 2.27rem 1.16rem;
    background-color: rgba(1,12,63,0.7);
    box-shadow: 0 4px 2px 2px #000000;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

@media only screen and (max-width: 1020px) {
    .SearchResults {
        width: 90%;
        margin-bottom: 2rem;
    }
}